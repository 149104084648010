
.paymentOption .option {
  width: 45%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  cursor: pointer;
  padding-top: 30px;
  padding-bottom: 30px;
}
.child-option {
  display: flex;
  gap: 20px;
  align-items: center;
}
.paymentOption hr {
  width: 45%;
  margin: 0px auto;
  color: #000;
}
.paymentOption a {
  color: #000000 !important;
}
@media only screen and (max-width: 800px) {
    .paymentOption h1 {
        font-size: 26px;
    }
    .paymentOption .option {
        width: 65%;
    }
    .paymentOption hr{
      width: 65%;
    }
}
@media only screen and (max-width: 500px) {
    .paymentOption h1 {
        font-size: 18px;
    }
    .paymentOption .option {
        width: 85%;
        padding-top: 20px;
    padding-bottom: 20px;
    }
    .paymentOption hr{
      width: 85%;
    }
    .paymentOption a {
        font-size: 16px;
    }
    
}