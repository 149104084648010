.ranking-container {
  background-image: url("../assets/img/bg-payment.png");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f6f4ea;
  height: auto;
  background-size: cover;
  padding-top: 20%;
  margin-top: -17%;
  padding-bottom: 20vh;
}
.ranking-container h3 {
  margin-top: 50px;
  margin-bottom: 50px;
  color: #000;
  font-family: "Roboto", "Helvetica";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.table>:not(caption)>*>*{
    background-color: #f6f4ea !important;

}
@media only screen and (max-width: 992px) {
  .ranking-container h3 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 450PX) {
  .ranking-container h3 {
    font-size: 20px;
  }
}
