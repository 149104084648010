.header-payment {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}
.header-payment div {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.header-payment div img {
  width: auto;
  height: auto;
}
.header-payment div p {
  margin: 0 auto;
  font-weight: 600;
  font-size: smaller;
}
.header-payment .left {
  padding-right: 25px;
  border-right: 2px solid gray;
}
.right {
  padding-left: 17px;
}
.paymentOption{
  margin-top: -20%;
  background-image: url("../assets/img/bg-payment.png");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #F6F4EA;
  height: auto;
  background-size: cover;
  padding-top: 20%;
  padding-bottom: 20vh;
}
.header-payment-container h1 {
  margin-top: 50px;
  margin-bottom: 50px;
  color: #000;
  font-family: "Roboto", "Helvetica";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.header-payment-container a {
  color: #000000 !important;
}
@media only screen and (max-width: 800px) {
  .header-payment div p {
    font-size: 14px;
  }
  .header-payment div img {
    width: 28px;
  }
}
