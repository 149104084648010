.information-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 20px auto;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 24px 100px;
}
.information-container p,
.information-container a {
  color: #fff;
  font-family: "Roboto", "Helvetica";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
  padding: 0px;
}
.information-container a {
  color: #fff !important;
}
@media only screen and (max-width: 800px) {
  .information-container {
    padding: 20px 80px;
  }
  .information-container p,
  .information-container a {
    font-size: 16px;
  }
}
