.sidebar-container-open {
  width: 15%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 20px auto;
  padding: 24px 100px;
  position: fixed;
  top: 10%;
  right: 0%;
  z-index: 1000;
  cursor: pointer;
  transition: transform 0.7s ease; /* Thêm transition cho smooth effect */
}
.sidebar-container-open img {
  width: 100%;
}
.sidebar-container-close {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  margin: 20px auto;
  position: fixed;
  top: 18%;
  right: 0%;
  z-index: 1000;
  cursor: pointer;
  transition: transform 0.5s ease; /* Thêm transition cho smooth effect */
}

.sidebar-img {
  position: absolute;
  z-index: 1;
}

.button-sidebar {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  z-index: 2;
  margin: 85% auto;
  margin-left: 12%;
}

@media only screen and (max-width: 992px) {
  .sidebar-container {
    display: none;
  }
  .sidebar-container-close {
    display: none;
  }
  .sidebar-container-open img {
    display: none;
  }
}
