.milestone {
  background: linear-gradient(90deg, #561d1d 0%, #bc3f3f 50%, #bc3f3f 100%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: -20%;
}
.milestone .bg-milestone {
  width: 577px;
  margin: 48px auto;
  margin-top: 25%;
}
.milestone .text-milestone p {
  color: white;
  font-weight: 500;
  font-size: 18px;
  line-height: 20.2px;
  font-family: "Roboto", "Helvetica";
}
.card-milestone {
  margin: 20px 20px 0 20px;
  width: 100.65% !important;
  justify-content: center;
  position: relative;
}
.card-milestone div {
  margin-bottom: 24px;
  position: relative;
}
.card-milestone .button {
  position: relative;
  width: 50%;
  margin-top: -45%;
  z-index: 1000;
  transition: margin-top 0.3s;
  transition: transform 0.3s;
  cursor: pointer;
}
.card-milestone .button:active {
  cursor: pointer;
  margin-top: -67%;
}
.card-milestone div h5 {
  margin-top: -123%;
  padding: 8px;
  color: #ffffff; /* Màu chữ */
  z-index: 1; /* Đảm bảo tiêu đề đè lên hình ảnh */
}
.card-milestone img {
  width: 80%;
}
.milestone {
  padding-bottom: 56px;
}
.milestone-footer img {
  width: 30%;
}
.card-milestone .img-milestone:hover {
  z-index: -1000;
  transform: scale(1.02);
  cursor: pointer;
}

.button-container {
  margin: 0 auto;
  margin-left: 5%;
}

.card-milestone .button-container:hover .img-milestone,
.card-milestone .button-container:hover .button {
  transform: scale(1.02);
}

.card-milestone .img-milestone:hover .button {
  transform: scale(1.05);
}

.card-milestone .img-milestone {
  width: 100%;
  transition: transform 0.3s;
}

.milestone .information-container {
  position: absolute;
  top: 25%;
  left: 2%;
}

@media only screen and (max-width: 1400px) {
  .card-milestone div .button {
    margin-top: -50%;
  }
  .card-milestone .button:active {
    margin-top: -72%;
  }
  .milestone .information-container {
    position: static !important;
  }
}

@media only screen and (max-width: 1000px) {
  .milestone .bg-milestone {
    width: 477px;
  }
  .card-milestone div .button {
    width: 50%;
    margin-top: -58%;
  }
  .card-milestone .button:active {
    cursor: pointer;
    margin-top: -80%;
  }
}
@media only screen and (max-width: 600px) {
  .milestone .bg-milestone {
    width: 377px;
    margin-top: 30%;
  }
  .milestone .text-milestone p {
    line-height: 20.2px;
    font-size: 13px;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .card-milestone div .button {
    margin-top: -60%;
  }
  .card-milestone .button:active {
    cursor: pointer;
    margin-top: -82%;
  }
}

@media only screen and (max-width: 575px) {
  .card-milestone .img-milestone {
    width: 70%;
  }
  .card-milestone div .button {
    width: 40%;
    margin-top: -30%;
  }
  .card-milestone .button:active {
    margin-top: -48%;
  }
}

@media only screen and (max-width: 500px) {
  .milestone .bg-milestone {
    width: 277px;
  }
}
