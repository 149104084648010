.charge {
  margin-top: -20%;
  background-image: url("../assets/img/bg-payment.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 20%;
  height: auto;
  padding-bottom: 30vh;
  background-color: #f6f4ea;
}
.charge h1 {
  margin-top: 10vh;
  margin-bottom: 30px;
  color: #000;
  font-family: "Roboto", "Helvetica";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.form-charge {
  width: 50%;
  margin: 0 auto;
}
.form-charge div {
  display: flex;
  align-items: center;
}
.form-charge div label {
  width: 20%;
  text-align: start;
  color: #000;
  font-family: "Roboto", "Helvetica";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form-charge div select,
.form-charge div input {
  width: 80%;
  display: flex;
}
.qr-code-container {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
}
.qr-code-container img {
  width: 20%;
  margin: 0 auto;
}
.qr-code-container button {
  width: 20%;
  margin: 20px auto;
}
@media only screen and (max-width: 800px) {
  .form-charge {
    width: 80%;
    margin: 0 auto;
  }
  .qr-code-container img {
    width: 60%;
    margin: 0 auto;
  }
  .qr-code-container button {
    width: 50%;
    margin: 20px auto;
  }
  .charge h1{
    font-size: 30px;
  }
  .form-charge div label{
    width: 40%
  }
}
@media only screen and (max-width: 500px) {
  .form-charge {
    width: 95%;
    margin: 0 auto;
  }
  .qr-code-container img {
    width: 80%;
    margin: 0 auto;
  }
  .qr-code-container button {
    width: 70%;
  }
  
}
