.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  max-width: 90% !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

p,
label,
a {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  text-decoration: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form-control {
  font-family: "Roboto", "Helvetica" !important;
}
.form-control:focus {
  font-family: "Roboto", "Helvetica" !important;
  border-color: #ed7404 !important;
  box-shadow: 0 0 0 0.25rem hwb(32 5% 2% / 0.251) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 1150px) {
  .container {
    max-width: 100% !important;
  }
}
