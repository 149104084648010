.avt {
  width: 25px;
  height: 25px;
  margin-top: -2px;
  margin-right: 7px;
  border-radius: 2px;
  border: 1px whitesmoke solid;
}
.link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #fff;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: 0.15s ease-in-out;
  transition-property: color, background-color, border-color;
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
  font-family: "Roboto", "Helvetica";
  font-size: 20px;
}
.link:hover,
.link:active,
.link:focus {
  color: #c1b7ba;
}
.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}
.navbar-expand-lg .navbar-nav .nav-link {
  display: inline-block;
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
  border: none;
  color: #fff;
  justify-content: flex-end;
  font-family: "Roboto", "Helvetica";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.navbar-expand-lg .navbar-nav img {
  display: flex;
  width: 175px;
  height: auto;
  justify-content: center;
  align-items: center;
}
.logo-img {
  width: 80%;
}
@media only screen and (max-width: 1200px) {
  .navbar-expand-lg .navbar-nav img {
    width: 135px;
  }
}
@media only screen and (max-width: 992px) {
  .logo-img {
    width: 118px;
  }
  .navbar-nav {
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
  }
  .navbar-collapse {
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
  }
  nav .show {
    height: 90vh;
    position: relative;
    z-index: 1000;
  }
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-start;
  }
  .navbar-nav {
    width: 100% !important;
  }
  .navbar-expand-lg .navbar-nav{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link,
  .navbar-expand-lg .navbar-nav .link {
    border-bottom: 1px solid #6f6f6f;
    padding-bottom: 20px;
    width: 100% !important;
    padding-bottom: 20px !important;
    margin-top: 0px;
  }
  .navbar-expand-lg .navbar-nav .nav-link:hover {
    color: #aca4a6;
  }
}
