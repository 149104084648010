.forgot-password2-container {
  background-image: url("../assets/img/authbg.png");
  background-size: cover;
  width: 100%;
  height: auto;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: -17%;
  background-position: center; /* Trung tâm hình ảnh nền để nó luôn ở giữa */
}
form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.form-change-password2 {
  height: 684px !important;
}
.form-forgot-password2 {
  margin: 320px auto;
  width: 754px;
  height: auto;
  padding: 100px 120px 120px 120px;
  border-radius: 20px;
  gap: 48px;
  background-color: #00000080;
}
.form-forgot-password2 h1 {
  font-size: 55px;
  font-weight: 700;
  font-weight: bolder;
  font-family: "Roboto", "Helvetica";
  background: linear-gradient(270deg, #ffa723 2.94%, #ed7404 100.83%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent; /* Fallback if background-clip is not supported */
  display: inline-block; /* Needed to apply padding and border */
  padding: 10px; /* Adjust as needed */
  position: relative; /* Ensure this doesn't disrupt your layout */
  -webkit-text-stroke: 0.5px white; /* Specify the width and color of the stroke */
}

.form-forgot-password2 .form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-forgot-password2 h1::before {
  content: attr(data-text); /* Use the data-text attribute for the text */
  display: block;
  background: -webkit-linear-gradient(
    360deg,
    #ffa723,
    #ed7404,
    #ffffff
  ); /* For Chrome and Safari */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: absolute;
  z-index: -1;
}
.form-forgot-password2 label {
  display: flex;
  text-align: start;
  color: white;
  color: #fff;
  font-family: "Roboto", "Helvetica";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.form-forgot-password2 p {
  color: white;
  font-size: 16px;
}
.form-forgot-password2 .link {
  display: flex;
  justify-content: space-between;
}
.form-forgot-password2 .link a {
  font-size: 16px;
  color: white;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", "Helvetica";
}
.form-forgot-password2 button {
  color: white;
  background: linear-gradient(90deg, #ffa723 2.89%, #ed7404 114.47%);
  border: 0;
  border-radius: 5px;
  padding: 12px 20px 12px 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", "Helvetica";
}
@media only screen and (max-width: 800px) {
  .form-forgot-password2 {
    width: 654px;
    height: auto;
    padding: 64px 64px 64px 64px;
    border-radius: 20px;
    gap: 48px;
    background-color: #00000080;
    margin-top: 150px;
  }
}
@media only screen and (max-width: 680px) {
  .form-forgot-password2 {
    width: 360px;
    height: auto;
    padding: 32px 32px 32px 32px;
    border-radius: 20px;
    gap: 48px;
    background-color: #00000080;
  }
  .form-forgot-password2 h1 {
    font-size: 30px;
  }
  .form-change-password2 {
    height: 524px !important;
  }
}
