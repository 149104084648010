nav .collapse {
  padding-left: 0px;
  padding-right: 0px;
}
a {
  font-size: larger;
}
.main img {
  width: 100%;
  height: auto;
}
.navbar-text {
  display: flex;
  gap: 15px;
}
.navbar-nav li {
  margin-right: 20px;
}
#navbarText a {
  color: white;
}
.navbar-text a {
  padding: 6px 12px;
  border-radius: 20px;
}
.navbar-text #login {
  color: #000000;
  background-color: white;
}
.navbar-text #register {
  border: 2px solid white;
}
.button-download {
  display: flex;
  justify-content: center;
  margin-top: -17%;
}

.main {
  margin-top: -10.5%;
}
.main .img-home2 {
  margin-top: 7.5%;
}
.main .img-home3 {
  margin-top: -1.7%;
  position: relative;
  z-index: 10;
}
.img-home4 {
  width: 100%; /* Đảm bảo rằng hình ảnh phủ kín chiều rộng của container */
  display: block; /* Xóa khoảng trắng dưới hình ảnh */
  z-index: -1000;
}

.main .button-download img {
  width: 282px;
  height: auto;
}

.buttonv2 {
  display: flex;
  flex-direction: column;
}
.buttonv2 .v2 img {
  width: 200px;
  height: auto;
  margin-left: 30px;
}

.img-home5 {
  position: relative;

  margin-top: -6%;
  z-index: 5;
}

.link-home5 {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -25%;
  align-items: center;
  padding-bottom: 11.5%;
  z-index: 10;
}
.link-home5 a {
  width: 500px;
  height: auto;
  margin-bottom: -0.5%;
}

.carousel-indicators {
  display: none !important; /* Ẩn indicators mặc định của Bootstrap */
}

.slider-carosel img {
  width: 407px;
  height: auto;
  flex-shrink: 0;
}

.container-carousel {
  position: relative;
  width: 100%; /* Đảm bảo rằng nó chiếm toàn bộ chiều rộng cần thiết */
  display: inline-block; /* Điều này giúp các thành phần bên trong căn theo chiều ngang */
  margin-top: -6.5%;
  overflow: hidden;
}

.slider-carousel {
  position: absolute;
  top: 50%; /* Đặt vào giữa chiều cao của ảnh */
  left: 50%; /* Đặt vào giữa chiều rộng của ảnh */
  transform: translate(-50%, -50%); /* Điều chỉnh chính xác vị trí giữa */
  width: 80%; /* Hoặc bất kỳ độ rộng nào bạn muốn */
  z-index: 10; /* Đảm bảo nó nằm trên hình ảnh */
  overflow: hidden;
}

.custom-carousel-indicators {
  display: flex;
  justify-content: center; /* Căn giữa các indicators theo chiều ngang */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin-top: -15%;
  width: 30px;
}

.custom-indicator {
  flex: 0 0 auto; /* Không cho phép các indicators co giãn */
  overflow: hidden;
}

.carousel-border-container {
  position: absolute;
  top: 55%; /* Đặt container vào giữa chiều cao của hình ảnh */
  left: 50%; /* Đặt container vào giữa chiều rộng của hình ảnh */
  transform: translate(-50%, -50%); /* Điều chỉnh chính xác vị trí giữa */
  width: 100%; /* Điều chỉnh chiều rộng theo ý muốn */
  background: url("../assets/img/Vector\ 14.png") center center no-repeat;
  width: 85%;
}
.carousel {
  width: 100%;
  max-width: 100%;
}
.carousel-border-container .img-slider {
  width: 65%;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 125px;
  padding-bottom: 125px;
  padding-left: 0px;
  padding-right: 0px;
}

.main .information-container {
  position: absolute;
  top: 30%; /* Vị trí giữa theo chiều dọc */
  left: 15%; /* Vị trí giữa theo chiều ngang */
  transform: translate(-50%, -50%); /* Căn giữa hoàn hảo */
  color: white; /* Màu chữ tương phản với hình ảnh nền */
  text-align: center; /* Căn giữa nội dung bên trong */
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  margin-left: 0px;
  padding-left: 0px;
  margin-right: 0px;
  padding-right: 0px;
}

@media only screen and (max-width: 1700px) {
  .main {
    margin-top: -10.5%;
  }
  .carousel-border-container .img-slider {
    width: 75%;
  }
  .main .img-home2 {
    margin-top: 7%;
  }
}
@media only screen and (max-width: 1650px) {
  .link-home5 {
    padding-bottom: 7.5%;
  }
}
@media only screen and (max-width: 1600px) {
  .button-download img {
    width: 252px !important;
  }
  .buttonv2 .v2 img {
    width: 170px;
  }
  .button-download {
    margin-top: -17.5%;
  }
  .main {
    margin-top: -11.5%;
  }
}

@media only screen and (max-width: 1400px) {
  .button-download img {
    width: 222px !important;
  }
  .buttonv2 .v2 img {
    width: 140px;
  }
  .carousel-border-container {
    background: none;
  }
  .carousel-control-next svg,
  .carousel-control-prev svg {
    width: 50px;
  }
  .main {
    margin-top: -15.5%;
  }
}

@media only screen and (max-width: 1300px) {
  .button-download img {
    width: 202px !important;
  }
  .buttonv2 .v2 img {
    width: 120px;
  }
}

@media only screen and (max-width: 1100px) {
  .button-download img {
    width: 182px !important;
  }
  .buttonv2 .v2 img {
    width: 100px;
  }
  .main {
    margin-top: -17%;
  }
}

@media only screen and (max-width: 992px) {
  #navbarText a {
    font-size: smaller;
  }
  nav .collapse {
    padding-left: 0px;
    padding-right: 0px;
  }
  .navbar-text {
    flex-direction: column;
  }
  .navbar-nav li {
    margin: 0 auto;
  }
  .navbar-text #login {
    display: flex;
    justify-content: center;
  }
  .navbar-text #register {
    display: flex;
    justify-content: center;
  }
  .navbar-toggler-icon {
    width: 1em;
    height: 1em;
  }

  .button-download img {
    width: 222px !important;
  }

  .main .img-home2 {
    margin-top: 4.5%;
  }
  .buttonv2 .v2 img {
    width: 80px;
    margin-top: -5px;
  }
  .carousel-control-next svg,
  .carousel-control-prev svg {
    width: 30px;
  }
  nav {
    background: #000;
    z-index: 1000;
  }
  .main {
    margin-top: 0;
  }
  .main .information-container {
    top: 22%;
    left: 50%;
  }
}
@media only screen and (max-width: 900px) {
  .button-download img {
    width: 222px !important;
  }
  .main .img-home2 {
    margin-top: 3%;
  }
  .buttonv2 .v2 img {
    width: 70px;
  }
}
@media only screen and (max-width: 800px) {
  .button-download img {
    width: 202px !important;
  }
  .main .img-home2 {
    margin-top: 2.5%;
  }
  .buttonv2 .v2 img {
    width: 60px;
  }
}
@media only screen and (max-width: 650px) {
  .button-download img {
    width: 182px !important;
  }
  .main .img-home2 {
    margin-top: 2%;
  }
  .buttonv2 .v2 img {
    width: 50px;
    margin-top: -10px;
  }

  .custom-carousel-indicators {
    width: 20px;
    margin-top: -20%;
  }
  .main .information-container {
    top: 19%;
    padding: 24px 66px;
    width: 80%;
  }
}
@media only screen and (max-width: 550px) {
  .button-download img {
    width: 142px !important;
  }
  .buttonv2 .v2 img {
    width: 50px;
    margin-top: -10px;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 450px) {
  .button-download img {
    width: 112px !important;
  }
  .buttonv2 .v2 img {
    width: 40px;
    margin-top: -20px;
    margin-left: 0px;
  }
  .img-home5 {
    margin-top: -10%;
  }
}
@media only screen and (max-width: 420px) {
  .custom-carousel-indicators {
    width: 15px;
    margin-top: -22%;
  }
  .carousel-control-next svg,
  .carousel-control-prev svg {
    width: 20px;
  }
  .carousel-border-container .img-slider {
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .main .information-container {
    top: 25%;
    padding: 24px 66px;
    width: 80%;
  }
}

@media only screen and (max-width: 360px) {
  .button-download img {
    width: 100px !important;
  }
  .buttonv2 .v2 img {
    width: 30px;
    margin-top: -30px;
    margin-left: 0px;
  }
  .main .information-container {
    top: 22%;
  }
}
