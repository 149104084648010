.payment-history-container {
  margin-top: -20%;
  background-image: url("../assets/img/bg-payment.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 20%;
  background-color: #F6F4EA;
}
.payment-history-container hr {
  width: 60%;
  margin: 0 auto;
  color: #b5b5b5;
}
.payment-history {
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-bottom: 15px;
}
.payment-history .left p {
  color: #646060;
  font-size: 14px;
  text-align: left;
}
.payment-history .right p {
  color: #21c01e;
  font-size: 14px;
  text-align: end;
}
.payment-history h5 {
  text-align: start;
}
@media only screen and (max-width: 800px) {
  .payment-history-container hr {
    width: 90%;
    margin: 0 auto;
    color: #b5b5b5;
  }
  .payment-history {
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 15px;
  }
  .payment-history h5 {
    font-size: 100%;
    text-align: start;
  }
}
